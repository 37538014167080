import one from '../Images/nit.jpg'
import spons3 from '../Images/newspons5.jpeg'
import spons4 from '../Images/newspons6.jpeg'
import spons6 from '../Images/newspons8.png'
import sjain from '../Images/sjain.jpg'
import stock from '../Images/stock.jpg'
import Spark from '../Images/spark.jpg'
import magic from '../Images/magic.jpg'
import bhaiya from '../Images/bhaiya.jpg'
import pixel from '../Images/pixel.jpg'
import salon from '../Images/salon.jpeg'
import bench from '../Images/bench.jpg'

import health from '../Images/health.png'

import hospital from '../Images/hospital.png'
import grab from '../Images/Grab.avif'
import burger from '../Images/bhuger.png'
import bhaat from '../Images/bhaat.png'
import anna from '../Images/anna.png'
const Spons = [
   {
      url:one
     }, {
      url: sjain
     },
     {
    url:spons3
   },{
      url:stock
     },{
    url:spons4
   },{
    url:spons6
   },{
    url:Spark
   },{
      url:magic
     },{
      url:bhaiya
     },{
      url:pixel
     },{
      url:salon
     },{
      url:health
     },{
      url:bench
     },{
      url:hospital
     },{
      url:bhaat
     },{
      url:grab
     },{
      url:burger
     },{
      url:anna
     }



]

export default Spons