import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";

const MultiActionAreaCard = ({img, name, branch, domain, mobile, email}) => {
  return (
    <div className="Main_card" >
      <CardActionArea>
        <div style={{display:"flex" , justifyContent:"center", alignItems:"center"}}>
        <img src={img} className="member_image" alt="" srcset="" />
        </div>
        <CardContent>
          <Typography  gutterBottom variant="h6" component="div">
            {name}
          </Typography>
          <Typography variant="body2" >
            {branch}  
          </Typography>
          <Typography variant="body2" >
            {domain} 
          </Typography>
          <Typography variant="body2" >
            {mobile}  
          </Typography>
          {/* <Typography variant="body2" >
            {email}  
          </Typography> */}
          <div style={{display:"flex" , justifyContent:"center", alignItems:"center"}}>
            <address>  <a href={`mailto:${email}`} ><button className="contact_button">Contact Us</button></a> </address> 
        </div>
        </CardContent>
      </CardActionArea>
     
    </div>
  );
};

export default MultiActionAreaCard
