



const MiniEvents = [
      {
        e_desc: "Am I worthy?",
        desc: "Boys and Girls will be given separate weights to lift, the overall score will be calculated by multipying the total weight lifted and no. of reps, highest score wins"
       
       },
       {
        e_desc: "NITRR got talent",
        desc: "The competition will include various talent categories such as singing, dancing, instrumental performance, stand-up comedy, magic, poetry recitation, etc., "
       
       },
       {
        e_desc: "Guess game ",
        desc: "Headphones with music on will be put on to a team player and he/she has to guess what their teammate is enacting"
       
       },
       {
        e_desc: "Pixel Lane",
        desc: "Pictures from the click club will be diplayed in pixel lane for an exhibition"
       
       },
       {
        e_desc: "Kalopsia art gallery",
        desc: "Various unique items will be placed at the art gallery for exhibiting"
       
       },
       {
        e_desc: "Jenga showdown",
        desc: "Participants can form teams of two, only one hand can be used to remove blocks,a common time limit is 30 seconds per turn."
       
       },
       {
        e_desc: "JumpRope Jam",
        desc: "Speed Round: Participants aim to complete as many continuous jumps as possible within a set time."
       
       },
       {
        e_desc: "Ballon poppers",
        desc: "Participants have to make a pyramid out of paper cups, which they will place using the inflated balloons stuck up in their mouth, the earliest to make the pyramid wins"
       
       },
       {
        e_desc: "Superhero Sway",
        desc: "A foot and palm bridge will be created for clicking photos and enjoying"
       
       },
       {
        e_desc: "Rangoli",
        desc: "Rangoli Competition "
       
       },
       {
        e_desc: "Quiz",
        desc: "First round will be a common knowledge quiz, next rounds will be a theme based quiz."
       
       },
       {
        e_desc: "Rangoli",
        desc: "Each team can comprise of maximum 3 members.They have to make rangoli using colors provided by us."
       
       },
     
       {
        e_desc: "Darts",
        desc: "Participants will have to use their creativity to design a bouquet by making flowers using colored paper"
       
       },
       {
        e_desc: "Hansard",
        desc: "1 member will given a word .  Both of them have to draw sketches to make their team mates to guess the word."
       
       },
       {
        e_desc: "Papertopia ",
        desc: "students who are interested can come forward and need to perform thier mimicry skills, or recreate a famous personality."
       
       },
       {
        e_desc: "Bottle Painting",
        desc: " One member has to design an outfit for the second member of his team using the newspaper and other given items.  "
       
       }
 
]

export default MiniEvents